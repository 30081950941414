import { Div } from "../reuseable-styles/div/div.styles";

import {
  FooterDiv,
  ButtonDiv,
  SocialLogo,
  ImagesLink,
  CopyrightDiv,
  PrivacyDiv,
} from "./footer.styles";
import { GithubFooterLogo } from "../../routes/individual-project-pages/image-links";

const Footer = () => (
  <FooterDiv>
    <Div>
      <p>
        github:
        <br /> &darr; &darr;
      </p>

      <ButtonDiv>
        <a href="https://github.com/jonmoakes">
          <SocialLogo src={GithubFooterLogo} alt="gihub logo" />
        </a>
      </ButtonDiv>

      <PrivacyDiv>
        <span>
          <ImagesLink to="/image-credits">image credits</ImagesLink>
        </span>
      </PrivacyDiv>

      <PrivacyDiv>
        <span>
          <ImagesLink to="/data-protection">Data Protection</ImagesLink>
        </span>
      </PrivacyDiv>

      <CopyrightDiv>
        <span>Copyright © Solaris Apps 2025</span>
      </CopyrightDiv>
    </Div>
  </FooterDiv>
);

export default Footer;
